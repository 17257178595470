.container {
    display: flex;
    align-items: center;
    width: 100%;
    user-select: none;
    border-radius: 12px;
    border: 1px solid #e6eaf0;
    cursor: default !important;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.page {
    margin: 0 !important;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;
    min-height: 72px;

    padding: 16px;
}

.hover {
    &:hover {
        cursor: pointer !important;
        border-radius: 12px;
        background: #f3f6f9;
    }
}

.info {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 4px;
}

.select {
    background: #f8f9fa;
    border-color: #000;
}

.disabled {
    opacity: 0.5;
}

.error {
    color: #fe4641;
}

.errorContainer {
    cursor: pointer;
}
