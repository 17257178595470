.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 0;
}

.containerAddress {
    @media (--snow-tablet) {
        border-radius: 12px;
        background-color: #f8fafc;
    }
}

.title {
    margin-top: 16px;
}

.description {
    text-align: center;
    margin-top: 8px;
    color: #898993;
}
